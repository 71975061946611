import React, { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function AdminSignIn(props) {
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [errorMsg, setError] = useState("");

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const login = async (e) => {
    e.preventDefault();
      if (values.email === "devin@admin.com" && values.password === "MoopDevin@1910") {
        setError("");
        await sessionStorage.setItem("admin", "true");
        await navigate("/adminDashboard");
      }
      else setError("Invalid email or password");
  };

  return (
    <>
      <div className="bg-white font-DMSans">
        <div className="flex h-screen bg-[url('./img/Splash-old.png')] bg-cover">
          <div className="w-11/12 sm:w-3/4 mx-auto lg:w-1/2">
            <section className="h-[96%] lg:h-full flex flex-col justify-center lg:block">
              <div className="px-6 h-5/6 sm:h-2/3 lg:h-[96%] text-gray-800">
                <div className="flex justify-center items-center flex-wrap h-full g-6 rounded-tl-[90px] rounded-br-[90px] lg:bg-transparent lg:rounded-none">
                  <div className="sm:w-[450px] w-full bg-white rounded-lg py-10 px-5">
                    <form>
                      <div className="flex flex-col items-center justify-center lg:block">
                        <p className="text-2xl sm:text-[36px] sm:w-full font-bold text-[#E6007C] mb-8">
                          Admin Sign In
                        </p>
                        {/* <!-- Email input --> */}
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#E6007C] ">
                          <label>Email*</label>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 bg-white  bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="mail@simmmple.com"
                          />
                        </div>
                        {/* <!-- Password input --> */}
                        <div className="text-xs sm:text-sm mb-6 w-full font-medium leading-[14px] tracking-[-0.02em] text-[#E6007C] ">
                          <label>Password*</label>
                          <div className="flex items-center justify-center mt-2 border border-solid border-gray-300 bg-white  rounded-xl">
                            <input
                              type={values.showPassword ? "text" : "password"}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              className="form-control block w-[90%] rounded-xl px-4 py-2 text-gray-700  bg-white  bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                              placeholder="Min. 8 characters"
                            />
                            <button
                              onClick={handleClickShowPassword}
                              className="text-[#A3AED0]"
                            >
                              {values.showPassword ? (
                                <BiHide size={20} />
                              ) : (
                                <BiShow size={20} />
                              )}
                            </button>
                          </div>
                        </div>

                        {errorMsg ? <p className="text-sm text-center text-red-700 font-semibold">{errorMsg}</p> : null}

                        <div className="w-full text-center text-xs sm:text-sm">
                          <button
                            type="button"
                            onClick={login}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#E6007C] rounded-2xl py-5 sm:my-5"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
