import React, { useState } from "react";
import EditNft from "./EditNft";
import { FaLongArrowAltLeft } from "react-icons/fa";

export default function DetailsNFT(props) {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <div className="mx-5">
        <div className="flex h-full w-full gap-x-8 font-Poppins">
          <div className="flex flex-col max-w-[500px] lg:w-[35%] md:w-[50%] w-full">
            <h1 className="text-[20px] mt-10 flex items-center font-extrabold cursor-pointer" onClick={() => props.setValues({ nft: "", image: "", attributes: "", description: "", })}><FaLongArrowAltLeft className="mr-3" /> Back</h1>
            <div className="flex justify-center my-10 w-full">
              <img src={props.image} alt="" className="rounded-lg mt-3" />
            </div>
            <div className="w-full text-white">
              <button onClick={() => setEdit(true)} className="bg-[#E6007C] w-full flex justify-center items-center gap-x-2 rounded-lg py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.423 2.5754C20.7353 1.88777 19.8027 1.50146 18.8302 1.50146C17.8578 1.50146 16.9251 1.88777 16.2375 2.5754L3.26773 15.5474C2.8895 15.9254 2.62854 16.4046 2.51623 16.9274L1.51648 21.5924C1.49012 21.7149 1.49487 21.8421 1.53031 21.9623C1.56575 22.0825 1.63075 22.1919 1.71936 22.2805C1.80798 22.3691 1.91739 22.4341 2.03759 22.4696C2.1578 22.505 2.28496 22.5098 2.40748 22.4834L7.07248 21.4836C7.59528 21.3713 8.07449 21.1104 8.45248 20.7321L21.4237 7.7609C22.1112 7.07315 22.4974 6.14055 22.4974 5.16815C22.4974 4.19574 22.1112 3.26314 21.4237 2.5754H21.423ZM17.298 3.6359C17.7075 3.2448 18.2537 3.02948 18.8199 3.03598C19.3861 3.04248 19.9273 3.27029 20.3277 3.67068C20.7281 4.07107 20.9559 4.61225 20.9624 5.17845C20.9689 5.74465 20.7536 6.29092 20.3625 6.7004L19.6875 7.3754L16.623 4.31165L17.298 3.6359ZM15.5625 5.37215L18.627 8.43665L7.39273 19.6724C7.2189 19.8461 6.99856 19.966 6.75823 20.0174L3.22498 20.7749L3.98248 17.2424C4.03392 17.0021 4.15373 16.7817 4.32748 16.6079L15.561 5.3729L15.5625 5.37215Z"
                    fill="white"
                  />
                </svg>
                <span className="text-lg font-medium">Edit Moopion</span>
              </button>
            </div>
          </div>

          <div className="lg:w-[60%] mt-10 space-y-6">
            <div className="space-y-5">
              <h1 className="text-[40px] font-extrabold">Moopian # {props.nft}</h1>

              <p className="text-[#747475]">
                <span className="line-clamp-2">
                  Moopian : {props.description}
                </span>
                {/* <span className="text-[#E6007C] cursor-pointer">Read More</span> */}
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="13"
                  viewBox="0 0 17 13"
                  fill="none"
                >
                  <path
                    d="M15.8261 6.42277C15.8617 6.46812 15.8617 6.53197 15.8261 6.57731L11.501 12.0771C11.4773 12.1072 11.4411 12.1248 11.4028 12.1248L1 12.125C0.930965 12.125 0.875 12.069 0.875 12L0.875 1.0001C0.875 0.931066 0.930965 0.875102 0.999998 0.875101L11.4028 0.875005C11.4411 0.875004 11.4773 0.892599 11.501 0.922737L15.8261 6.42277Z"
                    stroke="black"
                    strokeWidth="1.75"
                    strokeLinejoin="round"
                  />
                </svg>
                <h3 className="text-lg font-extrabold">Traits</h3>
              </div>

              <div className="flex justify-between flex-wrap gap-4 max-h-[62vh] overflow-scroll scroll-hide">
                {props.attributes.map((data, index) => (
                  <div key={index} className="lg:w-[30%] w-full bg-white border-[0.5px] rounded-xl">
                    <div className="text-xs sm:text-sm w-full text-[#27262E] font-medium text-center space-y-3 py-3">
                      <p>{props.attributes[index].trait_type}</p>
                      <p className="text-[#E6007C] font-extrabold">{props.attributes[index].value}</p>
                      {/* <p>Floor: --</p> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {edit
        ? <EditNft close={setEdit} attributes={props.attributes} image={props.image} nft={props.nft} description={props.description} />
        : null
      }
    </>
  );
}
