import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  WagmiConfig,
  createClient,
} from "wagmi";

import { configureChains } from 'wagmi'
import { bscTestnet } from '@wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
const { chains, provider, webSocketProvider } = configureChains(
  [bscTestnet],
  [
    publicProvider({ priority: 1 }),
  ],
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
  ],
  provider,
  webSocketProvider,
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={client}>
        <App />
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
