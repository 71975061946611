import React from "react";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";

export default function Traits(props) {
  return (
    <>
      <div className="">
        <div className="text-xs sm:text-base text-[#B9B8BC] flex ml-4 my-6">
          <div className="flex items-center -mr-10 z-10">
            <CiSearch className="text-2xl" />
          </div>
          <input
            type="number"
            name="nft"
            min={1}
            max={20000}
            className="form-control block w-2/5 pl-12 pr-6 rounded-[35px] text-[#B9B8BC] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4"
            placeholder="Search Item, Collection and Account.."
          />
        </div>

        <div className="mb-4">
          <div className="border-b border-b-[#DBDBDB] flex text-lg font-extrabold bg-[#fdebf5] rounded-t-xl">
            <h3 className="w-[60%] lg:w-[65%] xl:w-[70%] p-[22px] border-r border-r-[#DBDBDB]">
              TRAITS
            </h3>
            <h3 className="w-[40%] lg:w-[35%] xl:w-[30%] p-[22px]">ACTIONS</h3>
          </div>

          {props.layers.length
            ? props.layers.map((data, index) => (
              <div>
                <div className="border-b border-b-[#DBDBDB] flex font-medium">
                  <h3 className="w-[60%] lg:w-[65%] xl:w-[70%] p-[22px] border-r border-r-[#DBDBDB]">
                    {data.layer}
                  </h3>
                  <div className="w-[40%] lg:w-[35%] xl:w-[30%] px-[22px] flex items-center">
                    <Link
                      to={`/trait/${data.layer}`}
                      type="button"
                      className="font-medium text-sm text-[#E6007C] rounded-[4px] px-4 py-3 border border-[#E6007C]"
                    >
                      Manage
                    </Link>
                  </div>
                </div>
              </div>
            ))
            : null
          }
        </div>

        <div className="mb-32 text-sm opacity-50 flex max-[450px]:block max-[450px]:space-y-5">
          <div className="flex gap-1 max-[450px]:justify-center max-[450px]:w-full w-1/2 sm:w-[60%] lg:w-[65%] xl:w-[70%]">
            <p>Rows per page:</p>
            <p>20</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M4.94 5.22656L8 8.2799L11.06 5.22656L12 6.16656L8 10.1666L4 6.16656L4.94 5.22656Z"
                fill="#C2C9D1"
              />
              <path
                d="M4.94 5.22656L8 8.2799L11.06 5.22656L12 6.16656L8 10.1666L4 6.16656L4.94 5.22656Z"
                fill="black"
                fill-opacity="0.25"
              />
            </svg>
          </div>

          <div className="flex gap-1 max-[450px]:w-full max-[450px]:justify-center w-1/2 sm:w-[40%] lg:w-[35%] xl:w-[30%] items-center justify-between px-5">
            <p>1-20 of 200 items</p>

            <div className="flex gap-x-5 sm:gap-x-10 sm:mr-5">
              <div className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                >
                  <path
                    d="M7.41 11.09L2.83 6.5L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09Z"
                    fill="#C2C9D1"
                  />
                  <path
                    d="M7.41 11.09L2.83 6.5L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09Z"
                    fill="black"
                    fill-opacity="0.25"
                  />
                </svg>
              </div>

              <div className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                >
                  <path
                    d="M0.590088 11.34L5.17009 6.75L0.590088 2.16L2.00009 0.75L8.00009 6.75L2.00009 12.75L0.590088 11.34Z"
                    fill="#C2C9D1"
                  />
                  <path
                    d="M0.590088 11.34L5.17009 6.75L0.590088 2.16L2.00009 0.75L8.00009 6.75L2.00009 12.75L0.590088 11.34Z"
                    fill="black"
                    fill-opacity="0.25"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
