import "./App.css";
import { Routes, Route } from "react-router-dom";
import AdminSignIn from "./pages/AdminSignIn";
import AdminDashboard from "./pages/AdminDashboard";
import ManageTrait from "./pages/ManageTrait";
import ManageShop from "./pages/ManageShop";
import TraitsItem from "./pages/TraitsItem";

function App() {

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <AdminSignIn />
          }
        ></Route>
        <Route
          exact
          path="/adminDashboard"
          element={
            <AdminDashboard />
          }
        ></Route>
        <Route
          exact
          path="/traits"
          element={
            <ManageTrait />
          }
        ></Route>
        <Route
          exact
          path="/shop"
          element={
            <ManageShop />
          }
        ></Route>
        <Route
          exact
          path="/trait/:layer"
          element={
            <TraitsItem />
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
